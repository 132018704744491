import React, { useContext } from "react";

import SEO from "../components/SEO";
import LearnMore from "../components/partials/index/LearnMoreSection";
import Features from "../components/partials/schoolleader/FeaturesSection";
import SolidBGHeroSection from "@src/components/SolidBGHeroSection";
import FeaturesSection from "@src/components/partials/shared/FeaturesSection";
import SignUpCTASection from "@src/components/SignUpCTASection";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { graphql, useStaticQuery } from "gatsby";
import { theme } from "@src/components/nessie-web";

const {
  colors: { dt_aqua50 },
} = theme;

const IndexPage = () => {
  const t = useContext(TranslationContext);
  const data = useStaticQuery(graphql`
    {
      directus {
        page_teacherlp7 {
          feature_one_image {
            filename_disk
          }
          feature_two_image {
            filename_disk
          }
          feature_three_image {
            filename_disk
          }
        }
      }
    }
  `);

  const {
    directus: {
      page_teacherlp7: { feature_one_image, feature_two_image, feature_three_image },
    },
  } = data;

  const SolidBGHeroSectionProps = {
    title: "directus.page_teacherlp7.hero_title",
    subtitle: "directus.page_teacherlp7.hero_subtitle",
    sentence: "directus.page_teacherlp7.hero_text",
    BGColor: dt_aqua50,
    hero_button_text: "directus.page_teacherlp7.sign_up_button_text",
  };

  const FeaturesSectionProps = {
    features: [
      {
        img: feature_one_image,
        title: t.translate("directus.page_teacherlp7.feature_one_title"),
        description: t.translate("directus.page_teacherlp7.feature_one_text"),
      },
      {
        img: feature_two_image,
        title: t.translate("directus.page_teacherlp7.feature_two_title"),
        description: t.translate("directus.page_teacherlp7.feature_two_text"),
      },
      {
        img: feature_three_image,
        title: t.translate("directus.page_teacherlp7.feature_three_title"),
        description: t.translate("directus.page_teacherlp7.feature_three_text"),
      },
    ],
  };

  const SignUpCTASectionProps = {
    hero_button_text: "directus.page_teacherlp7.sign_up_button_text",
  };

  return (
    <>
      <SEO
        title="Learning that students love"
        description="With ClassDojo, your students’ attention is all yours. Engage their creativity while teaching with confidence!"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Learning that students love",
          description:
            "With ClassDojo, your students’ attention is all yours. Engage their creativity while teaching with confidence!",
        }}
      />
      <SolidBGHeroSection {...SolidBGHeroSectionProps} />
      <FeaturesSection contentData={FeaturesSectionProps} />
      <SignUpCTASection {...SignUpCTASectionProps} />
      <LearnMore hideCta />
      <Features />
    </>
  );
};
export default IndexPage;
